body {
  margin: 0;
  font-family: Mulish, sans-serif, arial;
  background-color: #ffeedb;
  background-image: url("https://www.transparenttextures.com/patterns/clean-textile.png");  background-color: #FFEEDB;
  box-sizing: border-box;
  overflow-y: scroll;
}

.nav-bar {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  background-color: white;
  position: fixed;
  width: 100%;
  align-items: center;
  z-index: 2;
}

@media only screen and (max-width: 315px){
  .nav-bar{
    justify-content: space-around;
  }
  body .nav-bar-name{
    margin-top: 10px;
  }
  body .name {
    padding-right: 15px;
  }
}

@media only screen and (max-width: 1000px){
  body .bio_container{
    display: block;
  }

  body .title {
    padding: 10px 30px
  }
}

@media only screen and (max-width: 1080px){
  body .cards-grid{
    justify-content: center;
  }
}


.name {
  padding-left: 15px;
  text-decoration: none;
  color: black;
  font-weight: bold;
  font-size: 16px;
}

.home, .contact, .projects {
  float: right;
  padding: 20px 16px;
  text-decoration: none;
  color: black;
  font-size: 14px;
  /* font-size: 100%; */
  border: solid 2px transparent;
  -webkit-tap-highlight-color: transparent;
}

.home:hover, .contact:hover, .projects:hover {
  transition: 0.3s;
  border-bottom-color: #ED9B10;
}

.nav-bar-links .active{
  border-bottom-color: #ED9B10;
}

.bottom {
  padding-bottom: 20px;
}

.bio {
  flex-wrap: wrap;
  max-width: 1000px;
  margin: 0px auto;
  padding-top: 60px;
  align-content: center;
}

.bio_container {
  padding-top: 40px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  text-align: center;
  margin: auto;
}

.picture {
  width: 300px;
  height: 450px;
  margin: 10px auto;
  border-radius: 5px;
}

.title {
  /* word-wrap: break-word; */
  font-size: 30px;
  padding: 140px 30px;
  padding-bottom: 0px;
  font-weight: 600;
}

.subtitle {
  font-size: 20px;
  padding-top: 10px;
}

.text {
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 10px;
}

.link {
  color: black;
}

.page-container {
  display: flex;
  flex-flow: column;
  justify-content: center;
  padding-top: 80px;
  padding-bottom: 20px;
  max-width: 60em;
  margin: 0 auto;
}

.cards-setting {
  padding-top: 100px;
  max-width: 1080px;
}

.inner-page-header {
  text-align: center;
  padding-left: 40px;
  padding-right: 40px;
  padding-bottom: 20px;
}

@media only screen and (max-width: 450px){
  body .page-title {
    margin: 0px 40px;
  }
}

.page-title {
  font-size: 30px;
  font-weight: 600;
  overflow-wrap: break-word;
}

.section-padding {
  padding-top: 10px;
}

.code-block{
  padding: 10px 0px;
}

.section-75 {
  width: 75%;
  margin: auto;
}

.text-subtitle {
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  padding-top: 5px;
  padding-bottom: 10px;
}

.page-section {
  padding: 0px 40px;
  text-align: justify;
}

.back-arrow {
  width: 20px;
  position: fixed;
  top: 60px;
  left: 19px;
  font-size: 50px;
  text-decoration: none;
  color: black;
  -webkit-tap-highlight-color: transparent;
}

.cards-grid {
  display: flex;
  padding-bottom: 40px;
  flex-wrap: wrap;
  height: 80vh;
}

.card {
  z-index: 1;
  padding: 10px;
}

.full-img {
  width: 100%;
  padding-bottom: 10px;
}

.images-container {
  padding: 20px 0px;
  text-align: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-content: center;
}

.image-container {
  text-align: center;
  position: relative;
  padding: 12px 0px;
}

.caption {
  margin-top: -10px;
  text-align: center;
  font-style: italic;
}

.status {
  text-align: center;
  font-weight: bold;
}

/* TV Control */

.adafruit-sc {
  max-width: 100%;
  height: auto;
  padding-bottom: 10px;
}

.ifttt-sc {
  max-width: 100%;
  height: auto;
  padding-bottom: 10px;
}

/* Android */

.android-img {
  height: 400px; 
  padding-bottom: 10px;
}

.android-left {
  padding-right: 15px;
  float: left;
}

.android-right {
  padding-left: 15px;
  float: right;
}

.flex {
  display: flex;
  flex-direction: row-reverse;
}

@media only screen and (max-width: 600px){

  body .cards-grid{
    height: auto;
  }

  body .page-container {
    padding-top: 80px;
  }

  body .bio {
    padding-top: 40px;
  }
  
  body .android-img {
    width: 100%;
    height: auto;
  }

  body .android-left, .android-right{
    float: none;
    padding-left: 0px;
    padding-right: 0px;
    padding-top: 10px;
  }

  body .page-section {
    display: block;
  }

  body .monica-portrait {
    padding: 10px 0px;
    width: 100%;
    height: auto;
  }

  body .sbs-img {
    padding-top: 10px;
    width: 100%;
    height: auto;
  }

  body .section-75 {
    width: 100%;
  }

  body .des-img {
    width: 100%;
    height: auto;
    padding-bottom: 10px;
  }

  body .back-arrow {
    left: 2.5vw;
  }

}


/* Ideas Lab */

.video-container {
  padding-bottom:56.25%;
  margin-bottom: 10px;
  position:relative;
  height:0;
}

.video-container iframe,
.video-container embed,
.video-container object {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

/* Monica */

.monica-portrait {
  padding: 10px 10px 10px 0px;
  height: 400px;
  float: left;
}

.sbs-img-container {
  display: flex;
  flex-wrap: wrap;
}

.sbs-img {
  width: 40%;
  /* padding: 0px 10px; */
  margin: auto;
}

/* EERover */

.images-container-1 {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.des-img {
  width: 33%;
}

code {
  font-size: large;
}